import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  max-width: 350px;
  margin: 0 auto;

  ${MEDIA.MIN_PHONE`
    max-width: 375px;
    `}
  ${MEDIA.MIN_TABLET`
    max-width: 768px;
    `}
    ${MEDIA.MIN_DESKTOP`
    max-width: 900px;
    `}
    ${MEDIA.MIN_BIGGERDESKTOP`
    max-width: 1050px;
    `}
    ${MEDIA.MIN_BIGDESKTOP`
    max-width: 1350px;
    `}
    ${MEDIA.MIN_BIGGESTDESKTOP`
    max-width: 1600px;
    `}
`

const MarginWrapper = ({ children }) => <Container>{children}</Container>

MarginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MarginWrapper
