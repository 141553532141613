import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import gsap from 'gsap'
import IconContainer from 'components/Icons/IconContainer'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  cursor: pointer;
  visibility: hidden;
  ${MEDIA.MIN_DESKTOP`
  display: none;
    `}
`

const Svg = styled.svg``

const FirstPath = styled.path`
  transition: 0.2s ease-in-out;
  ${Svg}:hover & {
    transform: translate(-10px, -5px) scale(1.05);
    fill: ${(props) => props.theme.colors.blue};
  }
`
const SecondPath = styled.path`
  transition: 0.2s ease-in-out;
  ${Svg}:hover & {
    transform: translate(5px) scale(1.05);
    fill: ${(props) => props.theme.colors.blue};
  }
`

const ThirdPath = styled.path`
  transition: 0.2s ease-in-out;
  ${Svg}:hover & {
    transform: translate(-10px, 5px) scale(1.05);
    fill: ${(props) => props.theme.colors.blue};
  }
`

const FourthPath = styled.path`
  transition: 0.2s ease-in-out;
  ${Svg}:hover & {
    fill: #fff;
  }
`

const Hamburger = () => {
  const dispatch = useDispatch()
  const hamburgerContainerRef = useRef(null)
  const indexPageLoaded = useSelector((state) => state.state.indexPageLoaded)
  const tl = useRef()
  const tl2 = useRef()

  useEffect(() => {
    tl2.current = gsap.timeline({ paused: true })
    tl2.current.to(hamburgerContainerRef.current, {
      visibility: 'visible',
    })
    tl2.current.play()
  }, [])

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    if (!indexPageLoaded) {
      tl.current.from(hamburgerContainerRef.current, {
        x: 100,
        duration: 2,
        ease: 'power3.inOut',
      })
      tl.current.play()
    }
    return () => {
      tl.current.kill()
    }
  }, [indexPageLoaded])

  return (
    <Container
      ref={hamburgerContainerRef}
      onClick={() =>
        dispatch({
          type: 'TOGGLE_OVERLAY',
          toggle: true,
        })
      }
    >
      <IconContainer
        mobileHeight="70px"
        mobileWidth="70px"
        tabletHeight="80px"
        tabletWidth="80px"
        desktopWidth="90px"
        desktopHeight="90px"
      >
        <Svg viewBox="0 0 70 70">
          <FourthPath fill="#0047BA" d="M0 0h70v70H0z" />
          <FirstPath fill="#fff" d="M18 25h41v4H18z" />
          <SecondPath fill="#fff" d="M11 33h41v4H11z" />
          <ThirdPath fill="#fff" d="M18 41h41v4H18z" />
        </Svg>
      </IconContainer>
    </Container>
  )
}

export default Hamburger
