import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  width: ${(props) => props.mobileWidth};
  height: ${(props) => props.mobileHeight};

  ${MEDIA.MIN_TABLET`
     width: ${(props) => props.tabletWidth};
     height: ${(props) => props.tabletHeight};
  `};

  ${MEDIA.MIN_DESKTOP`
     width: ${(props) => props.desktopWidth};
     height: ${(props) => props.desktopHeight};
  `};
`

const IconContainer = ({
  mobileWidth,
  mobileHeight,
  tabletHeight,
  tabletWidth,
  desktopHeight,
  desktopWidth,
  children,
}) => (
  <Container
    tabletHeight={tabletHeight}
    tabletWidth={tabletWidth}
    desktopHeight={desktopHeight}
    desktopWidth={desktopWidth}
    mobileWidth={mobileWidth}
    mobileHeight={mobileHeight}
  >
    {children}
  </Container>
)

IconContainer.propTypes = {
  mobileWidth: PropTypes.string.isRequired,
  mobileHeight: PropTypes.string.isRequired,
  tabletHeight: PropTypes.string,
  tabletWidth: PropTypes.string,
  desktopHeight: PropTypes.string,
  desktopWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
}

IconContainer.defaultProps = {
  tabletHeight: '',
  tabletWidth: '',
  desktopHeight: '',
  desktopWidth: '',
}

export default IconContainer
