import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Facebook from 'components/Icons/Facebook'
// import LinkedIn from 'components/Icons/LinkedIn'
import LinktItems from 'components/Navbar/Links/LinktItems'
import MEDIA from 'utils/helpers/media'
import IconContainer from 'components/Icons/IconContainer'
import Overlay from './Overlay'
import CloseMenu from './CloseMenu'

const MainContainer = styled.div`
  position: relative;
  margin-top: 10vh;
  grid-template-rows: 60vh 1fr 20vh;
  display: grid;
  height: 100%;
  justify-content: center;
`

const LinksContainer = styled.div`
  display: grid;
  font-size: ${(props) => props.theme.fontSizes.mheadsection};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  grid-column-gap: 50px;
  justify-items: center;
  align-items: center;
  grid-template-columns: auto;
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.theadsection};
    `}
`

const IconsContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: auto;
  /* grid-template-columns: auto auto; */
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
`

const Menu = React.memo(() => {
  const componentDidMount = useRef(false)
  const overlayVisible = useSelector(({ state }) => state.overlayVisible)
  const tl = useRef()
  const overlayRef = useRef(null)
  const linkContainerRef = useRef(null)
  const iconsContainerRef = useRef(null)
  const closeMenuRef = useRef(null)

  useEffect(() => {
    tl.current = gsap
      .timeline({ paused: true })
      .to(overlayRef.current, {
        display: 'initial',
        width: '100%',
        duration: 0.3,
        ease: 'Expo.easeOut',
      })
      .from(linkContainerRef.current, {
        opacity: 0,
        y: -150,
        duration: 0.25,
        ease: 'Expo.easeOut',
      })
      .from(iconsContainerRef.current, {
        opacity: 0,
        y: 150,
        duration: 0.25,
        ease: 'Expo.easeOut',
      })
      .from(closeMenuRef.current, {
        opacity: 0,
        duration: 0.25,
        ease: 'Expo.easeOut',
      })
    return () => {
      tl.current.kill()
    }
  }, [])

  useEffect(() => {
    if (componentDidMount) {
      if (overlayVisible) {
        tl.current.play()
      } else {
        tl.current.reverse().duration(0.8)
      }
    } else componentDidMount.current = true
  }, [overlayVisible])

  return (
    <Overlay reference={overlayRef}>
      <CloseMenu reference={closeMenuRef} />
      <MainContainer>
        <LinksContainer ref={linkContainerRef}>
          <LinktItems isOverlay />
        </LinksContainer>
        <IconsContainer ref={iconsContainerRef}>
          <IconContainer
            mobileHeight="40px"
            mobileWidth="40px"
            tabletHeight="60px"
            tabletWidth="60px"
          >
            <Facebook />
          </IconContainer>
          {/* <IconContainer
            mobileHeight="40px"
            mobileWidth="40px"
            tabletHeight="60px"
            tabletWidth="60px"
          >
            <LinkedIn />
          </IconContainer> */}
        </IconsContainer>
      </MainContainer>
    </Overlay>
  )
})

export default Menu
