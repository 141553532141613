/* eslint-disable arrow-body-style */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import gsap from 'gsap'
import IconContainer from 'components/Icons/IconContainer'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  cursor: pointer;
  visibility: hidden;
  ${MEDIA.MIN_DESKTOP`
    position: absolute;
    `}
`

const Svg = styled.svg``

const FirstPath = styled.path`
  transition: 0.2s ease-in-out;
  ${Svg}:hover & {
    fill: ${(props) => props.theme.colors.white};
  }
`
const SecondPath = styled.path`
  transition: 0.2s ease-in-out;
  ${Svg}:hover & {
    fill: ${(props) => props.theme.colors.blue};
  }
`

const Logo = React.memo(() => {
  const dispatch = useDispatch()
  const indexPageLoaded = useSelector((state) => state.state.indexPageLoaded)
  const containerRef = useRef(null)
  const tl = useRef()
  const tl2 = useRef()

  useEffect(() => {
    tl2.current = gsap.timeline({ paused: true })
    tl2.current.to(containerRef.current, {
      visibility: 'visible',
    })
    tl2.current.play()
  }, [])

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    if (!indexPageLoaded) {
      tl.current.from(containerRef.current, {
        x: -containerRef.current.offsetWidth * 2,
        opacity: 0,
        duration: 2,
        ease: 'power3.inOut',
      })
      tl.current.play()
    }
    return () => {
      tl.current.kill()
    }
  }, [indexPageLoaded])

  const handleClick = () => {
    if (window.location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      return
    }
    dispatch({
      type: 'SET_TARGET_ROUTE',
      route: '/',
    })
    dispatch({
      type: 'RUN_TRANSITION',
      transition: true,
    })
  }

  return (
    <Container ref={containerRef} onClick={handleClick}>
      <IconContainer
        mobileHeight="70px"
        mobileWidth="70px"
        tabletHeight="80px"
        tabletWidth="80px"
        desktopWidth="100px"
        desktopHeight="100px"
      >
        <Svg viewBox="0 0 70 70">
          <FirstPath
            className="logoSvgTransition"
            fill="#0047BA"
            d="M0 0v70h70V0zm54.92 54.7h-6.64v-9.3L35.67 58h-9.39l15.13-15.11 13.51-13.43zm3.31-33.33L38.05 41.56l-4.69 4.7-15-15v23.43h-6.59V15.26l6.63 6.64 15 14.95L58.23 12z"
          />
          <SecondPath
            className="logoSvgTransition"
            fill="#fff"
            d="M58.23 11.98v9.39L38.05 41.56l-4.69 4.7L18.4 31.3v23.39h-6.63V15.26l6.63 6.64v.02l14.95 14.95 24.88-24.89zM54.92 29.46V54.7h-6.64v-9.3L35.67 58.02h-9.39l15.13-15.13 13.51-13.43z"
          />
        </Svg>
      </IconContainer>
    </Container>
  )
})

export default Logo
