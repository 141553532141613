import { css } from 'styled-components'
import BREAKPOINTS from 'utils/constants/breakpoints'

const MEDIA = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label]}px) {
      ${css(...args)};
    }
  `

  acc[`MIN_${label}`] = (...args) => css`
    @media (min-width: ${BREAKPOINTS[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export default MEDIA
