import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { navigate } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'

const Box = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  position: fixed;
  right: 0;
  z-index: 6;
  width: 0;
  height: 100vh;
  display: none;
`

const PageTransition = React.memo(() => {
  const tl = useRef()
  const componentDidMount = useRef(false)
  const transition = useSelector(({ state }) => state.transition)
  const targetRoute = useSelector(({ state }) => state.targetRoute)
  const dispatch = useDispatch()
  const boxRef = useRef(null)

  const onCompleteTransition = () => {
    dispatch({
      type: 'RUN_TRANSITION',
      transition: false,
    })
  }

  useEffect(() => {
    tl.current = gsap
      .timeline({
        paused: true,
        onComplete: onCompleteTransition,
      })
      .set(boxRef.current, {
        display: 'none',
        right: 0,
        left: 'auto',
      })
      .to(boxRef.current, {
        display: 'initial',
        width: '100%',
        duration: 0.3,
        ease: 'Expo.easeOut',
        onComplete: () => {
          navigate(targetRoute)
        },
      })
      .to(boxRef.current, {
        width: 0,
        duration: 0.3,
        left: 0,
        ease: 'Expo.easeOut',
        delay: 0.8,
      })
    return () => {
      tl.current.kill()
    }
  })

  useEffect(() => {
    if (componentDidMount.current) {
      if (transition) {
        tl.current.play()
      }
    } else componentDidMount.current = true
  }, [transition])

  return <Box ref={boxRef} />
})

export default PageTransition
