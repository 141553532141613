import React from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'

const Icon = styled.svg`
  &:hover {
    transform: scale(1.05);
  }
`

const Facebook = () => (
  <a
    aria-label="Facebook page link"
    target="blank"
    href={translations.links.facebook}
  >
    <Icon viewBox="0 0 41 41.001">
      <g data-name="Facebook Gray">
        <path fill="none" d="M0 0h41v41H0z" data-name="Rectangle 392" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M23.908 41.003v-18.5h6.315l.9-7.217h-7.215v-4.508c0-2.03.677-3.609 3.609-3.609h3.833V.629c-.9 0-3.156-.226-5.637-.226-5.413 0-9.247 3.383-9.247 9.473v5.413H10.15v7.214h6.315v18.5z"
          data-name="Path 1"
        />
      </g>
    </Icon>
  </a>
)

export default Facebook
