const theme = {
  colors: {
    blue: '#0047BA',
    black: '#000',
    white: '#fff',
    red: '#b50000',
  },
  fontSizes: {
    msmall: '1rem',
    tsmall: '1.4rem',
    msmallplus: '1.1rem',
    tsmallplus: '1.8rem',
    msmallplusbigger: '1.2rem',
    tsmallplusbigger: '1.8rem',
    mregular: '1.6rem',
    tregular: '2rem',
    mheadSmall: '1.4rem',
    theadSmall: '1.8rem',
    dheadSmall: '2.5rem',
    mheadregular: '2rem',
    theadregular: '2.8rem',
    mheadsectionsmall: '2.5rem',
    mheadsection: '2.7rem',
    dheadsection: '3.3rem',
    bdheadsection: '3.5rem',
    theadsection: '3.5rem',
    mprice: '3rem',
    tprice: '5rem',
    mheadXL: '3.5rem',
    theadXL: '6rem',
    dheadXL: '7rem',
    bdheadXL: '8rem',
    mheadXXL: '4rem',
    theadXXL: '7rem',
    bignum: '10rem',
  },
  fontWeights: {
    light: '300',
    regular: '400',
    medium: '500',
    bold: '600',
    bolder: '800',
  },
}

export default theme
