import React from 'react'
import Layout from 'hoc/Layout/layout'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import createStore from 'store/ReduxWrapper'

export const wrapRootElement = ({ element }) => (
  <Provider store={createStore()}>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </Provider>
)

export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>
