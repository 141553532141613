import * as actionTypes from '../actions/actionTypes'

const initialState = {
  indexPageLoaded: false,
  overlayVisible: null,
  targetRoute: '/',
  transition: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_OVERLAY:
      return {
        ...state,
        overlayVisible: action.toggle,
      }
    case actionTypes.SET_TARGET_ROUTE:
      return {
        ...state,
        targetRoute: action.route,
      }
    case actionTypes.RUN_TRANSITION:
      return {
        ...state,
        transition: action.transition,
      }
    case actionTypes.SET_INITIAL_LOAD:
      return {
        ...state,
        indexPageLoaded: true,
      }
    default:
      return state
  }
}

export default reducer
