import React from 'react'
import PropTypes from 'prop-types'
import Hamburger from 'components/Navbar/Hamburger/Hamburger'
import Logo from 'components/Navbar/Logo/Logo'
import styled, { createGlobalStyle } from 'styled-components'
import Menu from 'components/Navbar/Menu/Menu'
import PageTransition from 'components/UI/PageTransition/PageTransition'
// import Footer from 'components/Footer/Footer'
import MarginWrapper from 'components/UI/MarginWrapper/MarginWrapper'

const GlobalStyle = createGlobalStyle`
  *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  position: relative;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  cursor: default;
}

body {
  width: 100%;
  font-family: 'Frank Ruhl Libre';
  line-height: 1.5;
  font-size: 1.6rem;
  color: #000;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'pnum';
  font-feature-settings: 'pnum';
  font-variant-numeric: proportional-nums;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

video {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}
`
const ContentWrapper = styled.div`
  min-height: 100%;
`
const LayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;
`

const Layout = ({ children }) => (
  <LayoutWrapper>
    <GlobalStyle />
    <Hamburger />
    <Logo />
    <Menu />
    <PageTransition />
    <ContentWrapper>
      <MarginWrapper>
        <main>{children}</main>
      </MarginWrapper>
      {/* <MarginWrapper>
        <Footer />
      </MarginWrapper> */}
    </ContentWrapper>
  </LayoutWrapper>
)

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default Layout
