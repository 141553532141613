import React from 'react'
import PropTypes from 'prop-types'
import LinkItem from './LinkItem'

const links = [
  {
    route: '/',
    text: 'Strona Główna',
  },
  {
    route: '/oferta',
    text: 'Oferta',
  },
  // {
  //   route: '/wiadomosci',
  //   text: 'Wiadomości',
  // },
  {
    route: '/kontakt',
    text: 'Kontakt',
  },
]

const LinktItems = ({ isOverlay, black }) => (
  <>
    {links.map(({ route, text }) => (
      <LinkItem black={black} isOverlay={isOverlay} key={route} link={route}>
        {text}
      </LinkItem>
    ))}
  </>
)

LinktItems.propTypes = {
  isOverlay: PropTypes.bool.isRequired,
  black: PropTypes.bool,
}

LinktItems.defaultProps = {
  black: false,
}

export default LinktItems
