/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MEDIA from 'utils/helpers/media'
import isBrowser from 'utils/helpers/isBrowser'

// eslint-disable-next-line react/jsx-props-no-spreading
const SingleLink = styled.div`
  position: relative;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) =>
    props.black ? props.theme.colors.black : props.theme.colors.white};
  cursor: pointer;
  &:hover {
    transform: ${(props) => (props.noAnimation ? '' : 'scale(1.05)')};
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    left: 0;
    height: 1px;
    background-color: ${(props) =>
      props.blueUnderline ? props.theme.colors.blue : props.theme.colors.white};
    bottom: 0;
    transition: 0.2s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    right: 0;
    height: 1px;
    background-color: ${(props) =>
      props.blueUnderline ? props.theme.colors.blue : props.theme.colors.white};
    bottom: 0;
    transition: 0.2s ease-in-out;
  }

  &:hover:before,
  &:hover:after {
    width: 50%;
  }
  ${MEDIA.MIN_DESKTOP`
  font-weight: ${(props) =>
    props.activeLink
      ? props.theme.fontWeights.medium
      : props.theme.fontWeights.regular};
  &:before {
    height: 2px;
    width: ${(props) => (props.activeLink ? '50%' : '0')};
  }
  &:after {
    height: 2px;
    width: ${(props) => (props.activeLink ? '50%' : '0')};
  }
    `}
`

const LinkItem = ({
  pureLink,
  noAnimation,
  link,
  children,
  isOverlay,
  black,
  blueUnderline,
}) => {
  const dispatch = useDispatch()
  const overlayVisible = useSelector((state) => state.state.overlayVisible)
  const [isPage, setIsPage] = useState(null)
  const setSite = () => {
    if (isBrowser()) {
      setIsPage(window.location.pathname)
    }
  }

  useEffect(() => {
    setSite()
  }, [])

  const handleClick = () => {
    if (window.location.pathname === link && overlayVisible) {
      dispatch({
        type: 'TOGGLE_OVERLAY',
        toggle: false,
      })
      return
    }
    if (window.location.pathname === link) return
    dispatch({
      type: 'SET_TARGET_ROUTE',
      route: link,
    })
    if (!overlayVisible) {
      dispatch({
        type: 'RUN_TRANSITION',
        transition: true,
      })
    }
    if (isOverlay) {
      dispatch({
        type: 'TOGGLE_OVERLAY',
        toggle: false,
      })
      navigate(link)
    }
  }

  const a = () => {
    if (pureLink) {
      return <div onClick={handleClick}>{children}</div>
    }
    return (
      <SingleLink
        blueUnderline={blueUnderline}
        activeLink={isPage === link}
        black={black}
        noAnimation={noAnimation}
        onClick={handleClick}
      >
        {children}
      </SingleLink>
    )
  }

  return a()
}

LinkItem.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOverlay: PropTypes.bool,
  noAnimation: PropTypes.bool,
  black: PropTypes.bool,
  blueUnderline: PropTypes.bool,
}

LinkItem.defaultProps = {
  isOverlay: false,
  noAnimation: false,
  black: false,
  blueUnderline: false,
}

export default LinkItem
