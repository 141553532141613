import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

const Box = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    right: 35px;
    content: ' ';
    height: 70px;
    width: 2px;
    background-color: ${(props) => props.theme.colors.white};
  }
  &:before {
    transition: 0.2s ease-in-out;
    transform: rotate(45deg);
  }
  &:after {
    transition: 0.2s ease-in-out;
    transform: rotate(-45deg);
  }
  &:hover:before {
    transform: rotate(-45deg);
  }
  &:hover:after {
    transform: rotate(45deg);
  }
`

const CloseMenu = ({ reference }) => {
  const dispatch = useDispatch()
  const onClickHandler = () => {
    dispatch({
      type: 'TOGGLE_OVERLAY',
      toggle: false,
    })
  }

  return <Box ref={reference} onClick={onClickHandler} />
}

CloseMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  reference: PropTypes.any,
}

CloseMenu.defaultProps = {
  reference: () => {},
}

export default CloseMenu
