/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Box = styled.div`
  background: rgb(0, 56, 174);
  background: linear-gradient(
    180deg,
    rgba(0, 56, 174, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: fixed;
  left: 0;
  z-index: 4;
  width: 0;
  height: 100vh;
  display: none;
`
const Overlay = ({ reference, children }) => (
  <Box ref={reference}>{children}</Box>
)

Overlay.propTypes = {
  reference: PropTypes.any,
  children: PropTypes.node.isRequired,
}

Overlay.defaultProps = {
  reference: () => {},
}

export default Overlay
